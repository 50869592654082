import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Card, CardContent, IconButton } from '@mui/material';
import { format } from 'date-fns';
import PetsIcon from '@mui/icons-material/Pets'; // Importa l'icona del dinosauro
import CloseIcon from '@mui/icons-material/Close'; // Importa l'icona di chiusura
import DeleteIcon from '@mui/icons-material/Delete'; // Importa l'icona di cancellazione
import Roadmap from './Roadmap'; // Importa il componente Roadmap
import Valutazioni from './Valutazioni';
import PresenzeDinoalunnoView from './PresenzeDinoalunnoView';

import AlunnoSelector from './AlunnoSelector'; // Importa il nuovo componente
import Swal from 'sweetalert2'; // Importa SweetAlert2

import axios from '../axios';

const CourseMTDetailModal = ({ open, onClose, idCorso ,  refreshCourses}) => {
    const [course, setCourse] = useState(null);
    const [alunni, setAlunni] = useState([]);
    const [roadmaps, setRoadmaps] = useState({});

    const formatDateString = (dateString) => {
        const year = parseInt(dateString.substring(0, 4));
        const month = parseInt(dateString.substring(4, 6)) - 1;
        const day = parseInt(dateString.substring(6, 8));
        return format(new Date(year, month, day), 'dd/MM/yyyy');
    };

    const fetchCourseDetails = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/datahandler/corso-alunni-insegnante', {
                idCorso,
                token
            });
            if (response.data.length > 0) {
                setCourse(response.data[0]);
                setAlunni(response.data); // Assumendo che gli alunni siano inclusi nella risposta
                updateAllRoadmaps(response.data);
                refreshCourses();
            }
        } catch (error) {
            console.error('Error fetching course details:', error);
        }
    };

    const updateRoadmap = async (idAlunno) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('datahandler/corso-alunno-roadmap', {
                idCorso,
                idAlunno,
                token: token
            });
            setRoadmaps(prevRoadmaps => ({
                ...prevRoadmaps,
                [idAlunno]: response.data
            }));
        } catch (error) {
            console.error('Error updating roadmap:', error);
        }
    };

    const updateAllRoadmaps = (alunniData) => {
        alunniData.forEach(alunno => {
            if (alunno.idalunno) {
                updateRoadmap(alunno.idalunno);
            }
        });
    };

    const handleRemoveAlunno = async (idAlunno) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: "Vuoi davvero rimuovere questo alunno dal corso?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sì, rimuovi',
            cancelButtonText: 'Annulla'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = localStorage.getItem('token');
                try {
                    await axios.post('/datahandler/remove-alunno-dal-corso', {
                        idCorso,
                        idAlunno,
                        token: token
                    });
                    fetchCourseDetails(); // Ricarica i dettagli del corso dopo la rimozione
                    Swal.fire(
                        'Rimosso!',
                        'L\'alunno è stato rimosso dal corso.',
                        'success'
                    );
                } catch (error) {
                    console.error('Error removing alunno from corso:', error);
                    Swal.fire(
                        'Errore!',
                        'Si è verificato un errore durante la rimozione dell\'alunno.',
                        'error'
                    );
                }
            }
        });
    };


    const isCourseEnded = () => {
        if (!course) return false;
        const currentDate = new Date();
        const endDate = new Date(
            parseInt(course.data_fine_corso.substring(0, 4)),
            parseInt(course.data_fine_corso.substring(4, 6)) - 1,
            parseInt(course.data_fine_corso.substring(6, 8))
        );
        return currentDate > endDate;
    };

    useEffect(() => {
        if (open) {
            fetchCourseDetails();
        }
    }, [open]);

    if (!course) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                {course.post_title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box mt={2}>
                    <AlunnoSelector idCorso={idCorso} onAlunnoAdded={fetchCourseDetails} />
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Insegnante: ${course.nometeacher} ${course.cognometeacher}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Livello: ${course.livello}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Gruppo età: ${course.gruppoeta}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Lingua: ${course.lingua}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Tipo di corso: ${course.course_type}`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2">{`Dal: ${formatDateString(course.data_inizio_corso)} Al: ${formatDateString(course.data_fine_corso)}`}</Typography>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    {alunni.length > 0 && alunni[0].idalunno ? (
                        alunni.map((alunno) => (
                            <Card key={alunno.idalunno} sx={{ marginBottom: 2, backgroundColor: '#FFE0B2' }}>
                                <CardContent>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <PetsIcon /> {/* Aggiungi l'icona del dinosauro */}
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="h6">{`${alunno.nome} ${alunno.cognome}`}</Typography>
                                        </Grid>
                                        {!isCourseEnded() && (
                                            <Grid item>
                                                <IconButton color="error" onClick={() => handleRemoveAlunno(alunno.idalunno)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Typography variant="body2">{`Email: ${alunno.email}`}</Typography>
                                    <PresenzeDinoalunnoView idCorso={idCorso} idAlunno={alunno.idalunno}/>

                                    <Roadmap
                                        idCorso={idCorso}
                                        idAlunno={alunno.idalunno}
                                        dataInizioCorso={course.data_inizio_corso}
                                        nomeAlunno={alunno.nome}
                                        cognomeAlunno={alunno.cognome}
                                        roadmap={roadmaps[alunno.idalunno] || []}
                                        updateAllRoadmaps={fetchCourseDetails}
                                        updateRoadmap={updateRoadmap} // Passa la funzione updateRoadmap
                                    />
                                    <Valutazioni idAlunno={alunno.idalunno} />
                                </CardContent>
                            </Card>
                        ))
                    ) : (
                        <Typography variant="h5">Nessun alunno associato.</Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} color="info">
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CourseMTDetailModal;
