import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, IconButton } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import axios from '../axios';
import 'moment/locale/it';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.locale('it');

const CorsiInsegnanteAddModal = ({ open, onClose, onSubmit, selectedCourseData, refreshCourses }) => {
    const [courseType, setCourseType] = useState('');
    const [ageGroupOptions, setAgeGroupOptions] = useState([]);
    const [levelOptions, setLevelOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [schoolOptions, setSchoolOptions] = useState([]);

    const [formFields, setFormFields] = useState({
        startDate: null,
        endDate: null,
        ageGroup: '',
        level: '',
        language: '',
        day: '',
        startTime: null,
        endTime: null,
        school: '',
        hours: '',
        schoolFee: '',
        coursePrice: '',
        installments: ''
    });
    const [invalidFields, setInvalidFields] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && !selectedCourseData) {
            resetForm();
        }
    }, [open, selectedCourseData]);

    useEffect(() => {
        if (courseType) {
            fetchAgeGroups();
            fetchLevels();
            fetchLanguages();
        }
    }, [courseType]);

    useEffect(() => {
        fetchLevels();
        fetchLanguages();
    }, []);

    const resetForm = () => {
        setInvalidFields({});
        setFormFields({
            startDate: null,
            endDate: null,
            ageGroup: '',
            level: '',
            language: '',
            day: '',
            startTime: null,
            endTime: null,
            school: '',
            hours: '',
            schoolFee: '',
            coursePrice: '',
            installments: '',
            courseType:''
        });
        setIsFormValid(false);
        setCourseType('');
        setAgeGroupOptions([]);
        setLevelOptions([]);
        setLanguageOptions([]);
    };

    const fetchAgeGroups = async () => {
        try {
            const response = await axios.get('/anagrafiche/get_fasce', {
                params: { courseType }
            });
            setAgeGroupOptions(response.data);
        } catch (error) {
            console.error('Error fetching age groups:', error);
        }
    };

    const fetchLevels = async () => {
        try {
            const response = await axios.get('/anagrafiche/get_livelli');
            setLevelOptions(response.data);
        } catch (error) {
            console.error('Error fetching levels:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await axios.get('/anagrafiche/get_lingue');
            setLanguageOptions(response.data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    useEffect(() => {
        const fetchSchools = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token'); 

                const response = await axios.post('datahandler/scuole-insegnante', {
                    token: token,
                });

                setSchoolOptions(response.data);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
            setLoading(false);
        };

        fetchSchools();
    }, []);

    const handleCourseTypeChange = (event) => {
        setCourseType(event.target.value);
        setFormFields((prevFields) => ({
            ...prevFields,
            courseType: event.target.value
        }));
    };

    useEffect(() => {
        validateForm();
    }, [formFields, courseType]);

    useEffect(() => {
        if (selectedCourseData) {
            setFormFields({
                courseType: selectedCourseData.course_type || '',
                startDate: selectedCourseData.start_date ? moment(selectedCourseData.start_date) : null,
                endDate: selectedCourseData.end_date ? moment(selectedCourseData.end_date) : null,
                ageGroup: selectedCourseData.age_group || '',
                level: selectedCourseData.level || '',
                language: selectedCourseData.language || '',
                day: selectedCourseData.day || '',
                startTime: selectedCourseData.start_time ? moment(selectedCourseData.start_time, 'HH:mm') : null,
                endTime: selectedCourseData.end_time ? moment(selectedCourseData.end_time, 'HH:mm') : null,
                school: selectedCourseData.school || '',
                hours: selectedCourseData.hours || '',
                schoolFee: selectedCourseData.school_fee || '',
                coursePrice: selectedCourseData.course_price || '',
                installments: selectedCourseData.installments || '',

            });
            setCourseType(selectedCourseData.course_type || '');
        }
    }, [selectedCourseData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value
        }));
        setInvalidFields((prevInvalidFields) => ({
            ...prevInvalidFields,
            [name]: !value
        }));
    };

    const handleDateChange = (name, date) => {
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: date
        }));
        setInvalidFields((prevInvalidFields) => ({
            ...prevInvalidFields,
            [name]: !date
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isFormValid) {
            const token = localStorage.getItem('token');
            try {
                const endpoint = selectedCourseData ? '/datahandler/update-corso' : '/datahandler/add-corso';
                const payload = {
                    token: token,
                    courseType: courseType,
                    school: formFields.school,
                    startDate: formFields.startDate ? formFields.startDate.format('YYYY-MM-DD') : null,
                    endDate: formFields.endDate ? formFields.endDate.format('YYYY-MM-DD') : null,
                    language: formFields.language,
                    level: formFields.level,
                    day: formFields.day,
                    hours: formFields.hours,
                    orainizio: formFields.startTime ? formFields.startTime.format('HH:mm') : null,
                    orafine: formFields.endTime ? formFields.endTime.format('HH:mm') : null,
                    ageGroup: formFields.ageGroup,
                    schoolFee: formFields.schoolFee,
                    coursePrice: formFields.coursePrice,
                    installments: formFields.installments,
                    idCorso: selectedCourseData ? selectedCourseData.idCorso : null
                };

                const response = await axios.post(endpoint, payload);

                if (response.data.success) {
                    toast.success(selectedCourseData ? 'Corso aggiornato con successo' : 'Corso inserito con successo');
                    onSubmit(formFields);
                    onClose();
                } else if (response.data.error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Errore',
                        text: response.data.error,
                    });
                }
            } catch (error) {
                console.error('Errore nella richiesta:', error);
            }
        }
    };

    const validateForm = () => {
        const requiredFields = [
            'startDate', 'endDate', 'ageGroup', 'level', 'language', 'day', 'startTime', 'endTime', 'hours','courseType'
        ];
        if (courseType !== 'online') {
            requiredFields.push('school');
        }
        if (courseType === 'scolastico') {
            requiredFields.push('schoolFee');
        }
        if (courseType === 'extrascolastico') {
            requiredFields.push('coursePrice', 'installments');
        }
        const invalidFields = {};
        requiredFields.forEach(field => {
            if (!formFields[field]) {
                invalidFields[field] = true;
            }
        });
        setInvalidFields(invalidFields);
        setIsFormValid(Object.keys(invalidFields).length === 0);
    };

    const renderTextField = (name, label, type = 'text') => (
        <TextField
            label={label}
            type={type}
            name={name}
            value={formFields[name]}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{
                shrink: true
            }}
            error={!!invalidFields[name]}
            helperText={invalidFields[name] ? 'Questo campo è obbligatorio' : ''}
            InputProps={{
                inputProps: { min: 0 }
            }}
        />
    );

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="it">
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {selectedCourseData ? 'Modifica Corso' : 'Aggiungi Corso'}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                    <form id="addCourseForm">
                        <TextField
                            select
                            label="Tipo di Corso"
                            name="courseType"
                            value={courseType}
                            onChange={handleCourseTypeChange}
                            fullWidth
                            required
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={!!invalidFields.courseType}
                            helperText={invalidFields.courseType ? 'Questo campo è obbligatorio' : ''}
                        >
                            <MenuItem value="scolastico">Scolastico</MenuItem>
                            <MenuItem value="extrascolastico">Extrascolastico</MenuItem>
                            <MenuItem value="online">Online</MenuItem>
                        </TextField>

               
                            <>
                                <DatePicker
                                    label="Data Inizio"
                                    value={formFields.startDate}
                                    onChange={(date) => handleDateChange('startDate', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            margin="normal"
                                            error={!!invalidFields.startDate}
                                            helperText={invalidFields.startDate ? 'Questo campo è obbligatorio' : ''}
                                        />
                                    )}
                                />
                                <DatePicker
                                    label="Data Fine"
                                    value={formFields.endDate}
                                    onChange={(date) => handleDateChange('endDate', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            margin="normal"
                                            error={!!invalidFields.endDate}
                                            helperText={invalidFields.endDate ? 'Questo campo è obbligatorio' : ''}
                                        />
                                    )}
                                />
                                <TextField
                                    select
                                    label="Fascia di età"
                                    name="ageGroup"
                                    value={formFields.ageGroup}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!invalidFields.ageGroup}
                                    helperText={invalidFields.ageGroup ? 'Questo campo è obbligatorio' : ''}
                                >
                                    {ageGroupOptions.map((option) => (
                                        <MenuItem key={option.slug} value={option.slug}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    label="Livello"
                                    name="level"
                                    value={formFields.level}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!invalidFields.level}
                                    helperText={invalidFields.level ? 'Questo campo è obbligatorio' : ''}
                                >
                                    {levelOptions.map((option) => (
                                        <MenuItem key={option.term_id} value={option.term_id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    label="Lingua"
                                    name="language"
                                    value={formFields.language}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!invalidFields.language}
                                    helperText={invalidFields.language ? 'Questo campo è obbligatorio' : ''}
                                >
                                    {languageOptions.map((option) => (
                                        <MenuItem key={option.term_id} value={option.term_id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    label="Giorno"
                                    name="day"
                                    value={formFields.day}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!invalidFields.day}
                                    helperText={invalidFields.day ? 'Questo campo è obbligatorio' : ''}
                                >
                                    <MenuItem value="1">Lunedì</MenuItem>
                                    <MenuItem value="2">Martedì</MenuItem>
                                    <MenuItem value="3">Mercoledì</MenuItem>
                                    <MenuItem value="4">Giovedì</MenuItem>
                                    <MenuItem value="5">Venerdì</MenuItem>
                                    <MenuItem value="6">Sabato</MenuItem>
                                    <MenuItem value="7">Domenica</MenuItem>
                                </TextField>
                                <TimePicker
                                    label="Ora inizio"
                                    value={formFields.startTime}
                                    onChange={(time) => handleDateChange('startTime', time)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            margin="normal"
                                            error={!!invalidFields.startTime}
                                            helperText={invalidFields.startTime ? 'Questo campo è obbligatorio' : ''}
                                        />
                                    )}
                                />
                                <TimePicker
                                    label="Ora fine"
                                    value={formFields.endTime}
                                    onChange={(time) => handleDateChange('endTime', time)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            margin="normal"
                                            error={!!invalidFields.endTime}
                                            helperText={invalidFields.endTime ? 'Questo campo è obbligatorio' : ''}
                                        />
                                    )}
                                />

                                {courseType !== 'online' && (
                                    <TextField
                                        select
                                        label="Scuola"
                                        name="school"
                                        value={formFields.school}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={!!invalidFields.school}
                                        helperText={invalidFields.school ? 'Questo campo è obbligatorio' : ''}
                                    >
                                        {schoolOptions.map((option) => (
                                            <MenuItem key={option.ID} value={option.ID}>
                                                {option.post_title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}

                                {renderTextField('hours', 'Ore complessive del corso', 'number')}

                                {courseType === 'scolastico' && (
                                    renderTextField('schoolFee', 'Compenso ricevuto dalla scuola', 'number')
                                )}

                                {courseType === 'extrascolastico' && (
                                    <>
                                        {renderTextField('coursePrice', 'Prezzo del corso comprensivo del materiale didattico', 'number')}
                                        {renderTextField('installments', 'Numero rate', 'number')}
                                    </>
                                )}
                            </>
                     
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        sx={{
                            backgroundColor: 'orange',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'darkorange',
                            },
                        }}
                    >
                        Chiudi
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        sx={{
                            backgroundColor: 'orange',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'darkorange',
                            },
                        }}
                        disabled={!isFormValid}
                    >
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default CorsiInsegnanteAddModal;
