import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import axios from '../axios';
import Layout from './Layout';
import PresenzeDinoAlunniDetail from './PresenzeDinoAlunniDetail';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import CheckCircleIcon
import moment from 'moment';
import 'moment/locale/it';

const PresenzeDinoAlunni = () => {
    const [lessons, setLessons] = useState([]);

    const fetchLessons = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/datahandler/presenze-insegnante-lezioni', { token });
            setLessons(response.data);
        } catch (error) {
            console.error('Error fetching lessons:', error);
        }
    };

    useEffect(() => {
        fetchLessons();
    }, []);

    return (
        <Layout>
            <Box sx={{ width: '100%', mx: 'auto', px: 1, marginLeft: 0 }}>
                {lessons.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
                        <CheckCircleIcon sx={{ fontSize: 60, color: 'green' }} />
                        <Typography variant="h5" sx={{ mt: 2 }}>
                            Tutte le presenze alle tue lezioni sono state salvate
                        </Typography>
                    </Box>
                ) : (
                    lessons.map((lesson, index) => (
                        <Card key={index} sx={{ marginBottom: 2, marginLeft: 0 ,backgroundColor: '#d5d5f5'}}>
                            <CardHeader
                                title={lesson.post_title}
                            />
                            <CardContent>
                                <PresenzeDinoAlunniDetail
                                    idCorso={lesson.idcorso_fk}
                                    dataLezione={lesson.datalezione}
                                    onSaveSuccess={fetchLessons} // Pass the callback here
                                />
                            </CardContent>
                        </Card>
                    ))
                )}
            </Box>
        </Layout>
    );
};

export default PresenzeDinoAlunni;
