import React, { useState, useEffect } from 'react';
import { Toolbar, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import { Link, useLocation } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import PetsIcon from '@mui/icons-material/Pets';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Importa l'icona di download
import './Menu.css';  // Import the CSS file

const Menu = () => {
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(location.pathname);
    const [permissions, setPermissions] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchPermissions = () => {
            let permissions = [];
            try {
                permissions = JSON.parse(localStorage.getItem('permissions')) || [];
            } catch (error) {
                console.error('Error parsing permissions:', error);
                permissions = [];
            }
            setPermissions(permissions);
        };

        fetchPermissions();
    }, []);

    const handleListItemClick = (path) => {
        setSelectedIndex(path);
    };

    const getListItemStyle = (path) => ({
        backgroundColor: selectedIndex === path ? 'lightblue' : 'transparent',
    });

    const canAccess = (permission) => {
        if (permission === 'corsi-insegnante') return true;
        if (permissions.includes('all')) return true;
        return permissions.includes(permission);
    };

    return (
        <div>
            <Toolbar />
            <List>
                {canAccess('dashboard') && (
                    <ListItem
                        button
                        component={Link}
                        to="/dashboard"
                        selected={selectedIndex === '/dashboard'}
                        onClick={() => handleListItemClick('/dashboard')}
                        style={getListItemStyle('/dashboard')}
                    >
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                )}
                {canAccess('corsi-caricati') && (
                    <ListItem
                        button
                        component={Link}
                        to="/corsi-caricati"
                        selected={selectedIndex === '/corsi-caricati'}
                        onClick={() => handleListItemClick('/corsi-caricati')}
                        style={getListItemStyle('/corsi-caricati')}
                    >
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        <ListItemText primary="Corsi Caricati" />
                    </ListItem>
                )}

                {canAccess('corsi-caricati') && (
                    <ListItem
                        button
                        component={Link}
                        to="/globalroadmap"
                        selected={selectedIndex === '/globalroadmap'}
                        onClick={() => handleListItemClick('/globalroadmap')}
                        style={getListItemStyle('/globalroadmap')}
                    >
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        <ListItemText primary="Roadmap Globale" />
                    </ListItem>
                )}


                {canAccess('mt-inviomessaggi') && (
                    <ListItem
                        button
                        component={Link}
                        to="/mt-inviomessaggi"
                        selected={selectedIndex === '/mt-inviomessaggi'}
                        onClick={() => handleListItemClick('/mt-inviomessaggi')}
                        style={getListItemStyle('/mt-inviomessaggi')}
                    >
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Invio messaggi MT" />
                    </ListItem>
                )}
                {canAccess('magic-teachers-queue') && (
                    <ListItem
                        button
                        component={Link}
                        to="/magic-teachers-queue"
                        selected={selectedIndex === '/magic-teachers-queue'}
                        onClick={() => handleListItemClick('/magic-teachers-queue')}
                        style={getListItemStyle('/magic-teachers-queue')}
                    >
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Magic Teachers Queue" />
                    </ListItem>
                )}
                <ListItem
                    button
                    component={Link}
                    to="/notifications"
                    selected={selectedIndex === '/notifications'}
                    onClick={() => handleListItemClick('/notifications')}
                    style={getListItemStyle('/notifications')}
                >
                    <ListItemIcon>
                        <MessageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Notifiche" />
                </ListItem>
                {canAccess('template-email') && (
                    <ListItem
                        button
                        component={Link}
                        to="/template-email"
                        selected={selectedIndex === '/template-email'}
                        onClick={() => handleListItemClick('/template-email')}
                        style={getListItemStyle('/template-email')}
                    >
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Template Email" />
                    </ListItem>
                )}
              


                <ListItem
                    button
                    component={Link}
                    to="/corsi-insegnante"
                    selected={selectedIndex === '/corsi-insegnante'}
                    onClick={() => handleListItemClick('/corsi-insegnante')}
                    style={getListItemStyle('/corsi-insegnante')}
                >
                    <ListItemIcon>
                        <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="I miei corsi" />
                </ListItem>

                <ListItem
                    button
                    component={Link}
                    to="/presenze-dinoalunni"
                    selected={selectedIndex === '/presenze-dinoalunni'}
                    onClick={() => handleListItemClick('/presenze-dinoalunni')}
                    style={getListItemStyle('/presenze-dinoalunni')}
                >
                    <ListItemIcon>
                        <PetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Presenze Dinoalunni" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/miei-dinoalunni"
                    selected={selectedIndex === '/miei-dinoalunni'}
                    onClick={() => handleListItemClick('/miei-dinoalunni')}
                    style={getListItemStyle('/miei-dinoalunni')}
                >
                    <ListItemIcon>
                        <PetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="I miei Dinoalunni" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/areadownload"
                    onClick={() => handleListItemClick('/areadownload')}
                    style={{ backgroundColor: 'lightblue' }}
                >
                    <ListItemIcon>
                        <CloudDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary="Area Download Materiali" />
                </ListItem>
                <ListItem
                    button
                    component="a"
                    href={`https://www.hocus-lotus.edu/area-magic?token=${token}`}
                    onClick={() => handleListItemClick('https://www.hocus-lotus.edu/area-magic')}
                    style={{ backgroundColor: 'lightblue' }}
                >
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Area Magic" />
                </ListItem>
            </List>
        </div>
    );
};

export default Menu;
