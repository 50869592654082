import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Typography, Grid, IconButton, Tooltip, Button, CircularProgress , Snackbar, Alert  } from '@mui/material';
import axios from '../axios';
import Layout from './Layout';
import CourseMTDetailModal from './CourseMTDetailModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import Valutazioni from './Valutazioni';
import AlunnoModal from './AlunnoModal';

const MieiDinoAlunni = () => {
    const [alunni, setAlunni] = useState([]);
    const [loading, setLoading] = useState(true); // Stato per tracciare il caricamento
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedAlunnoId, setSelectedAlunnoId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [currentAlunno, setCurrentAlunno] = useState({
        first_name: '',
        last_name: '',
        email: '',
    });

  

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const fetchAlunni = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/datahandler/all-alunni-insegnante', { token });
            setAlunni(response.data);
        } catch (error) {
            console.error('Error fetching alunni:', error);
            setSnackbarMessage('Errore durante il caricamento degli alunni.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setLoading(false); // Imposta loading a false una volta terminato il caricamento
        }
    };


    useEffect(() => {
        fetchAlunni();
    }, []);

    const handleCourseClick = async (courseId) => {
        setSelectedCourseId(courseId);
    };

    const handleValutazioniClick = (alunnoId) => {
        setSelectedAlunnoId(alunnoId === selectedAlunnoId ? null : alunnoId);
    };

    const handleCreateAlunno = () => {
        setCurrentAlunno({
            first_name: '',
            last_name: '',
            email: '',
        });
        setOpenModal(true);
    };

    const handleEditAlunno = (alunno) => {
        setCurrentAlunno(alunno);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSaveAlunno = async () => {
        const token = localStorage.getItem('token');
        const url = currentAlunno.id ? '/datahandler/update-alunno' : '/datahandler/create-alunno';
        
        try {
            await axios.post(url, { ...currentAlunno, token });
            fetchAlunni();
            handleCloseModal();
            setSnackbarMessage('Alunno salvato con successo.');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error saving alunno:', error);

            // Check if the error response contains a specific message
            if (error.response && error.response.data && error.response.data.error) {
                setSnackbarMessage(error.response.data.error); // Show specific error from the response
            } else {
                setSnackbarMessage('Errore durante il salvataggio dell\'alunno.');
            }
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const renderCard = (alunno) => {
        return (
            <Card 
                key={alunno.id} 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    p: 2, 
                    backgroundColor: alunno.course_name ? '#c5f5c5' : '#f5f5f5', 
                    borderRadius: 2, 
                    boxShadow: 3, 
                    position: 'relative' 
                }}
            >
                <Tooltip title="Modifica Alunno">
                    <IconButton 
                        sx={{ position: 'absolute', top: 8, right: 8 }} 
                        onClick={() => handleEditAlunno(alunno)}
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={`ID: ${alunno.id}`}>
                    <CardHeader
                        title={`${alunno.first_name || 'Nome'} ${alunno.last_name || 'Cognome'}`}
                        subheader={alunno.uemail || ''}
                        titleTypographyProps={{ variant: 'h6', align: 'center' }}
                        subheaderTypographyProps={{ variant: 'body2', align: 'center' }}
                        sx={{ textAlign: 'center', mt: 1 }}
                    />
                </Tooltip>
                <CardContent>
                    {alunno.email && (
                        <Typography variant="body2" color="textSecondary" align="center">
                            Email comunicazioni: {alunno.email}
                        </Typography>
                    )}
                    {alunno.course_name && (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => handleCourseClick(alunno.course_id)}
                            sx={{ mt: 1, mb: 2 }} // Margine aggiunto per separare i pulsanti
                        >
                            <CheckCircleIcon sx={{ fontSize: 16, color: 'green', mr: 0.5 }} />
                            {alunno.course_name}
                        </Button>
                    )}<br/>
                    {alunno.hasValutazioni == '1' && (
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={() => handleValutazioniClick(alunno.id)}
                        >
                            Visualizza Valutazioni
                        </Button>
                    )}
                    {selectedAlunnoId === alunno.id && (
                        <Valutazioni idAlunno={alunno.id} />
                    )}
                </CardContent>
            </Card>
        );
    };

    return (
        <Layout>
            <Box sx={{ width: '100%', mx: 'auto', px: 2, marginLeft: 0 }}>
                <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
                    I Miei Dino Alunni
                </Typography>


  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleCreateAlunno}
                    sx={{ mb: 4 }}
                >
                    Crea Nuovo Alunno
                </Button>
              


                {loading ? ( // Mostra lo spinner mentre carica
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {alunni.map((alunno) => (
                            <Grid item xs={12} sm={6} md={4} key={alunno.id}>
                                {renderCard(alunno)}
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>

            <AlunnoModal 
                open={openModal} 
                onClose={handleCloseModal} 
                onSave={handleSaveAlunno} 
                alunno={currentAlunno} 
                setAlunno={setCurrentAlunno} 
            />

            {selectedCourseId && (
                <CourseMTDetailModal
                    open={Boolean(selectedCourseId)}
                    onClose={() => setSelectedCourseId(null)}
                    idCorso={selectedCourseId}
                    refreshCourses={fetchAlunni}
                />
            )}

<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>


        </Layout>
    );
};

export default MieiDinoAlunni;
