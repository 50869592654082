import React, { useEffect, useState } from 'react';
import axios from '../axios';
import { Box, CircularProgress, Typography, Button, Card, CardContent, Grid, IconButton, Tooltip, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import { DateRange, Language, School } from '@mui/icons-material';
import PetsIcon from '@mui/icons-material/Pets';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import CorsiInsegnanteAddModal from './CorsiInsegnanteAddModal';
import CourseMTDetailModal from './CourseMTDetailModal';

const levelColorMapping = {
    1: '#FFECB3', // Pastel Yellow
    2: '#C8E6C9', // Pastel Green
    3: '#B3E5FC', // Pastel Sky Blue
    4: '#BBDEFB', // Pastel Blue
    5: '#FFCDD2', // Pastel Red
    6: '#B2DFDB', // Pastel Teal Green
    7: '#E1BEE7', // Pastel Lavender
    8: '#FFE0B2', // Pastel Orange
};

const CorsiInsegnante = () => {
    const [loading, setLoading] = useState(false);
    const [corsi, setCorsi] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [motivation, setMotivation] = useState(''); // Stato per la motivazione
    const [notes, setNotes] = useState(''); // Stato per le note
    const [isSubmitting, setIsSubmitting] = useState(false); // Stato per il submit
    const [motivationExists, setMotivationExists] = useState(false);
    const [selectedCourseData, setSelectedCourseData] = useState(null);

    const fetchCorsi = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token'); // Get the token from local storage

            const response = await axios.post('datahandler/corsi-insegnante', {
                token: token,
            });

            setCorsi(response.data);
        } catch (error) {
            console.error('Error fetching corsi:', error);
        }
        setLoading(false);
    };

    const checkMotivationExists = async () => {
        try {
            const token = localStorage.getItem('token'); // Get the token from local storage
            const response = await axios.post('/datahandler/no-corsi-get-motivation', {
                token: token
            });

            if (response.data.motivations && response.data.motivations.length > 0) {
                setMotivationExists(true);
            } else {
                setMotivationExists(false);
            }
        } catch (error) {
            console.error('Error checking motivations:', error);
        }
    };


    useEffect(() => {
        fetchCorsi();
        checkMotivationExists(); // Controlla se ci sono motivazioni salvate
    }, []);

    const handleAddCourse = () => {
        setSelectedCourseData(null);  
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSaveCourse = (courseData) => {
        // Handle saving the course
        console.log('Course data:', courseData);
        handleCloseModal();
        fetchCorsi();
    };

    const handleCourseClick = async (courseId) => {
        setSelectedCourseId(courseId);
    };

    const handleDeleteCourse = async (courseId) => {
        const token = localStorage.getItem('token'); // Get the token from local storage

        Swal.fire({
            title: 'Sei sicuro?',
            text: "Questa operazione non può essere annullata!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sì, cancella!',
            cancelButtonText: 'Annulla'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.post('datahandler/delete-corso', {
                        token: token,
                        idCorso: courseId
                    });

                    if (response.data.success) {
                        toast.success('Corso eliminato con successo');
                        fetchCorsi(); // Ricarica i corsi
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Errore',
                            text: response.data.error
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Errore',
                        text: 'Errore nella richiesta. Si prega di riprovare.'
                    });
                    console.error('Errore nella richiesta:', error);
                }
            }
        });
    };

    const formatDateString = (dateString) => {
        const year = parseInt(dateString.substring(0, 4));
        const month = parseInt(dateString.substring(4, 6)) - 1;
        const day = parseInt(dateString.substring(6, 8));
        return format(new Date(year, month, day), 'dd/MM/yyyy');
    };

    const handleSubmitNoCorsi = async () => {
        setIsSubmitting(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('datahandler/no-corsi-save-motivation', {
                token: token,
                motivation: motivation,
                notes: notes,
            });
    
            if (response.data.success) {
                toast.success('Motivazione salvata con successo');
                setMotivation('');
                setNotes('');
    
                // Ricontrolla se esiste una motivazione e ricarica i corsi
                await checkMotivationExists();
                await fetchCorsi();
            } else {
                toast.error('Errore nel salvataggio della motivazione');
            }
        } catch (error) {
            toast.error('Errore nella richiesta. Si prega di riprovare.');
            console.error('Errore nella richiesta:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    
   const handleCourseEdit = async (courseId) => {
    try {
        const token = localStorage.getItem('token');

        // Effettua una chiamata per ottenere i dettagli del corso da modificare
        const response = await axios.post('datahandler/get-corso-details', {
            token: token,
            idCorso: courseId,
        });

        if (response.data.success) {
            // Popola il modal di aggiunta corso con i dettagli esistenti del corso
            setSelectedCourseData(response.data.course_details);  // Popola i campi esistenti
            setModalOpen(true);  // Apre il modal per l'editing
        } else {
            toast.error('Errore nel recupero dei dettagli del corso.');
        }
    } catch (error) {
        toast.error('Errore nella richiesta. Si prega di riprovare.');
        console.error('Errore nella richiesta:', error);
    }
};

    




    const renderCard = (corso) => {
        const backgroundColor = levelColorMapping[corso.livello] || '#FFE0B2'; // Default to pastel orange if no match
        const oggi = new Date().toISOString().slice(0, 10).replace(/-/g, '');
    
        const corsoIniziato = corso.data_inizio_corso <= oggi;
    
        return (
            <Card key={corso.ID} sx={{ marginBottom: 2, width: '100%', backgroundColor: backgroundColor, cursor: 'pointer' }}>
                <CardContent onClick={() => handleCourseClick(corso.ID)}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={11}>
                            <Typography variant="h6">{corso.post_title}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            {(!corso.numalunni || corso.numalunni === '0' || corso.numalunni === 0) && (
                                <Tooltip title="Elimina corso">
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteCourse(corso.ID);
                                    }}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={6}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <PetsIcon />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{`Numero di alunni: ${corso.numalunni}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <DateRange />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{`Dal: ${formatDateString(corso.data_inizio_corso)} Al: ${formatDateString(corso.data_fine_corso)}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Language />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{`Lingua: ${corso.lingua}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <School />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">{`Scuola: ${corso.scuola || 'N/A'}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{`Livello: ${corso.livello}`}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!corsoIniziato  && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button
                                 variant="contained"
                                    color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCourseEdit(corso.ID);
                                }}
                            >
                                Modifica Corso
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        );
    };
    

    return (
        <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', mt: 4 }}>
            {loading ? (
                <CircularProgress />
            ) : (
                <Box sx={{ width: '100%' }}>
                    {corsi.length === 0 ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                            <Typography variant="h5" sx={{ mb: 2 }}>Non ci sono corsi futuri caricati.</Typography>
                            
                            {/* Aggiungi Corso Section */}
                            <Box sx={{ width: '100%', maxWidth: 600, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#f5f5f5' }}>
                                <Typography variant="h6" gutterBottom>Aggiungi un nuovo corso</Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>Non hai ancora aggiunto un corso per i tuoi studenti.</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddCourse}
                                    fullWidth
                                >
                                    Aggiungi Corso
                                </Button>
                            </Box>
                         
                            {!motivationExists && (
                            <Box sx={{ width: '100%', maxWidth: 600, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#f5f5f5' }}>
                                <Typography variant="h6" gutterBottom>Fornisci una motivazione</Typography>
                                <Typography variant="body1" sx={{ mb: 2 }}>Se non puoi aggiungere corsi al momento, per favore fornisci una motivazione.</Typography>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel>Motivazione</InputLabel>
                                    <Select
                                        value={motivation}
                                        onChange={(e) => setMotivation(e.target.value)}
                                        label="Motivazione"
                                    >
                                        <MenuItem value="Nessun alunno disponibile">Nessun alunno disponibile</MenuItem>
                                        <MenuItem value="Problemi tecnici">Problemi tecnici</MenuItem>
                                        <MenuItem value="Problemi di salute">Problemi di salute</MenuItem>
                                        <MenuItem value="Altro">Altro</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Note"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleSubmitNoCorsi}
                                    disabled={isSubmitting}
                                    fullWidth
                                >
                                    Salva Motivazione
                                </Button>
                            </Box>
                            )}


                        </Box>
                    ) : (
                        <>
                            {/* Aggiungi Corso Button (Always Visible) */}
                            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', mb: 4 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddCourse}
                                    sx={{ alignSelf: 'flex-start' }}
                                >
                                    Aggiungi Corso
                                </Button>
                            </Box>

                            {/* Render Corsi */}
                            {corsi.map(corso => renderCard(corso))}
                        </>
                    )}
                </Box>
            )}
    
            {selectedCourseId && (
                <CourseMTDetailModal
                    open={Boolean(selectedCourseId)}
                    onClose={() => setSelectedCourseId(null)}
                    idCorso={selectedCourseId}
                    refreshCourses={fetchCorsi}
                />
            )}
        </Box>
    
        <CorsiInsegnanteAddModal
    open={modalOpen}
    onClose={handleCloseModal}
    onSubmit={handleSaveCourse}
    selectedCourseData={selectedCourseData}  // Passa i dati del corso selezionato al modal
    refreshCourses={fetchCorsi}
/>

        <ToastContainer />
    </Layout>
    
    );
};

export default CorsiInsegnante;
