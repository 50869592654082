// src/components/MagicTeachersQueue.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from '../axios';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_simple.min.css'; // use Theme(s)
import Layout from './Layout';
import moment from 'moment';
import 'moment/locale/it'; // Import Italian locale for moment

const MagicTeachersQueue = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post('/datahandler/magicteachersqueue', {
                    token
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const wrapTextFormatter = (cell, formatterParams, onRendered) => {
        onRendered(function() {
            cell.getElement().style.whiteSpace = "normal";
            cell.getElement().style.wordWrap = "break-word";
        });
        return cell.getValue();
    };

    const dateFormatter = (cell) => {
        const value = cell.getValue();
        return value ? moment(value).format('DD/MM/YYYY HH:mm') : '';
    };

    const columns = [
        { title: 'ID', field: 'id', headerFilter: 'input', resizable: true },
        { title: 'MT', field: 'mtname', headerFilter: 'input', resizable: true },
        { title: 'Recipient', field: 'recipient', headerFilter: 'input', resizable: true },
        { title: 'Type', field: 'msgtype', headerFilter: 'input', resizable: true },
        { 
            title: 'Message', 
            field: 'msg', 
            headerFilter: 'input', 
            width: 800,
            formatter: wrapTextFormatter,
            editor: false, // Disable editing
            resizable: true
        },
        { title: 'Status', field: 'status', headerFilter: 'input', resizable: true },
        { title: 'Data creazione', field: 'created_at', headerFilter: 'input', formatter: dateFormatter, resizable: true },
    ];

    const tableOptions = {
        height: "900px",
        resizableColumns: true,
        layout: "fitData"
    };

    return (
        <Layout>
            <Box>
                <div className="tabulator-container">
                    <ReactTabulator
                        data={data}
                        columns={columns}
                        options={tableOptions}
                    />
                </div>
            </Box>
        </Layout>
    );
};

export default MagicTeachersQueue;
