// src/components/CourseDetailsModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from './../axios';
import { ReactTabulator } from 'react-tabulator';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const CourseDetailsModal = ({ open, handleClose, userId, subtype }) => {
    const [courseDetails, setCourseDetails] = useState([]);

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const response = await axios.get(`/anagrafiche/dettagli-corsi-caricati?user_id=${userId}&subtype=${subtype}`, {
                    withCredentials: true,
                });
                setCourseDetails(response.data);
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        if (open) {
            fetchCourseDetails();
        }
    }, [open, userId, subtype]);

    const columns = [
        { title: 'Titolo Corso', field: 'post_title' },
        { title: 'Data Inizio', field: 'data_inizio_corso' },
        { title: 'Data Fine', field: 'data_fine_corso' },
        { title: 'DinoAlunni', field: 'numalunni' },
        { title: 'Roadmap', field: 'roadmap', formatter: () => "<button>Visualizza Roadmap</button>" }
    ];

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={modalStyle}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-title" variant="h6" component="h2">
                    Dettaglio Corsi
                </Typography>
                <ReactTabulator
                    data={courseDetails}
                    columns={columns}
                    layout="fitData"
                    options={{
                        pagination: "local",
                        paginationSize: 5,
                    }}
                />
            </Box>
        </Modal>
    );
};

export default CourseDetailsModal;
