import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../axios';

const AlunnoSelector = ({ idCorso, onAlunnoAdded }) => {
    const [alunni, setAlunni] = useState([]);
    const [selectedAlunno, setSelectedAlunno] = useState('');

    useEffect(() => {
        fetchAlunni();
    }, []);

    const fetchAlunni = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/datahandler/alunni-insegnante', { token });
            setAlunni(response.data);
        } catch (error) {
            console.error('Error fetching alunni:', error);
        }
    };

    const handleAddAlunno = async () => {
        if (!selectedAlunno) return;
        const token = localStorage.getItem('token');
        try {
            await axios.post('/datahandler/add-alunno-al-corso', {
                idCorso: idCorso,
                idAlunno: selectedAlunno,
                token
            });
            onAlunnoAdded(); // Chiama la funzione di aggiornamento nel componente padre
            setSelectedAlunno('');
        } catch (error) {
            console.error('Error adding alunno to corso:', error);
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
                <TextField
                    select
                    label="Seleziona dinoalunno"
                    value={selectedAlunno}
                    onChange={(e) => setSelectedAlunno(e.target.value)}
                    fullWidth
                >
                    {alunni.map((alunno) => (
                        <MenuItem key={alunno.id} value={alunno.id}>
                            {alunno.first_name} {alunno.last_name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <Tooltip title="Aggiungi dinoalunno al corso">
                <IconButton
                onClick={handleAddAlunno}
                sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    '&:hover': {
                        backgroundColor: 'primary.dark',
                    },
                }}
            >
                <AddIcon />
            </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default AlunnoSelector;
