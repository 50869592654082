import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import axios from '../axios';
import Swal from 'sweetalert2';

const TemplateEmailTable = ({ emailTemplates, fetchEmailTemplates, handleEdit }) => {

    const handleDelete = async (templateId) => {
        const token = localStorage.getItem('token');

        Swal.fire({
            title: 'Sei sicuro?',
            text: "Non potrai annullare questa operazione!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sì, elimina!',
            cancelButtonText: 'Annulla'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios({
                        method: 'post',
                        url: '/datahandler/delete-template',
                        data: {
                            token: token,
                            id: templateId
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        withCredentials: true,
                    });

                    Swal.fire(
                        'Eliminato!',
                        'Il template è stato eliminato.',
                        'success'
                    );

                    // Refetch the email templates after deleting
                    fetchEmailTemplates();
                } catch (error) {
                    console.error('Error deleting template:', error);
                    Swal.fire(
                        'Errore!',
                        'Si è verificato un errore durante l\'eliminazione del template.',
                        'error'
                    );
                }
            }
        });
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Codice</TableCell>
                    <TableCell>Oggetto</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Azioni</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {emailTemplates.map(template => (
                    <TableRow key={template.id}>
                        <TableCell>{template.id}</TableCell>
                        <TableCell>{template.codice}</TableCell>
                        <TableCell>{template.subject}</TableCell>
                        <TableCell>{template.messageType}</TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEdit(template.id)}
                                style={{ marginRight: '10px' }}
                            >
                                Modifica
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleDelete(template.id)}
                            >
                                Elimina
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default TemplateEmailTable;
