import React, { useState } from 'react';
import { Grid, Chip, Typography, Box, Snackbar, Alert, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from '../axios';

const MySwal = withReactContent(Swal);

const Roadmap = ({ idCorso, idAlunno, dataInizioCorso, nomeAlunno, cognomeAlunno, roadmap, updateAllRoadmaps, updateRoadmap }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const isCourseStarted = () => {
        const currentDate = new Date();
        const startDate = new Date(
            parseInt(dataInizioCorso.substring(0, 4)),
            parseInt(dataInizioCorso.substring(4, 6)) - 1,
            parseInt(dataInizioCorso.substring(6, 8))
        );
        return currentDate >= startDate;
    };

    const getChipProps = (item) => {
        const currentDate = new Date();
        const phaseDate = new Date(
            parseInt(item.datafase.substring(0, 4)),
            parseInt(item.datafase.substring(5, 7)) - 1,
            parseInt(item.datafase.substring(8, 10))
        );

        if (item.sent === '1') {
            return { backgroundColor: '#90ee90', color: 'black', clickable: false, tooltip: 'Messaggio inviato' };
        } else if (!isCourseStarted()) {
            return { backgroundColor: '#d3d3d3', color: 'black', clickable: false, tooltip: 'Corso non iniziato, impossibile inviare il messaggio' };
        } else if (item.sent === '0' && phaseDate < currentDate) {
            return { backgroundColor: '#ff6961', color: 'black', clickable: true, tooltip: 'Messaggio da inviare, data trascorsa' };
        } else {
            return { backgroundColor: '#fdfd96', color: 'black', clickable: true, tooltip: 'Messaggio non inviato' };
        }
    };

    const handleChipClick = async (item) => {
        const result = await MySwal.fire({
            title: `Invia ${item.codice}`,
            showCancelButton: true,
            confirmButtonText: `Invia ${item.codice} a tutti i dinoalunni del corso`,
            cancelButtonText: `Invia ${item.codice} a ${nomeAlunno} ${cognomeAlunno}`,
            showCloseButton: true
        });

        if (result.isConfirmed) {
            await sendViaggio({ idAlunno: 0, idCorso, idTemplate: item.id }, item.codice, true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            await sendViaggio({ idAlunno, idCorso, idTemplate: item.id }, item.codice, false);
        }
    };

    const sendViaggio = async (data, codice, updateCourse) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post('datahandler/send-viaggio', { ...data, token });
            showSnackbar(`${codice} inviato con successo`, 'success');
            if (updateCourse) {
                updateAllRoadmaps();  // Ricarica tutte le roadmap dopo l'invio
            } else {
                // Ricarica la roadmap per il singolo alunno
                updateRoadmap(data.idAlunno);
            }
        } catch (error) {
            console.error('Error sending viaggio:', error);
            showSnackbar('Errore durante l\'invio del viaggio', 'error');
            Swal.fire('Errore', 'Errore durante l\'invio del viaggio', 'error');
        }
    };

    return (
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary">Roadmap:</Typography>
            <Grid container spacing={1}>
                {roadmap.map(item => {
                    const { backgroundColor, color, clickable, tooltip } = getChipProps(item);
                    return (
                        <Grid item key={item.id}>
                            <Tooltip title={tooltip} arrow>
                                <Chip
                                    label={item.codice}
                                    sx={{
                                        backgroundColor,
                                        color,
                                        cursor: clickable ? 'pointer' : 'default',
                                        boxShadow: 'inset 1px 1px 2px rgba(0, 0, 0, 0.6), inset -1px -1px 2px rgba(255, 255, 255, 0.6)',
                                        '&:hover': {
                                            backgroundColor: clickable ? '#f0e68c' : backgroundColor,
                                            boxShadow: clickable ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
                                            transform: clickable ? 'scale(1.05)' : 'none',
                                        },
                                        transition: 'all 0.2s ease-in-out'
                                    }}
                                    onClick={() => clickable && handleChipClick(item)}
                                />
                            </Tooltip>
                        </Grid>
                    );
                })}
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}  // La metà del tempo
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Posizione in alto a destra
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Roadmap;
