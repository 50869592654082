// src/components/BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const palette = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'];

const BarChart = ({ title, labels, datasets, displayLegend = true }) => {
    const data = {
        labels,
        datasets: datasets.map((dataset, index) => ({
            ...dataset,
            backgroundColor: palette[index % palette.length]
        })),
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: displayLegend,
            },
            title: {
                display: true,
                text: title,
            },
        },
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
