import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, Card, CardContent, Grid } from '@mui/material';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator_simple.min.css';
import './CorsiCaricatiTable.css'; // Import custom styles
import './MtNoCorsiFuturi.css';
import Layout from './Layout';
import Swal from 'sweetalert2';
import axios from '../axios';
import moment from 'moment';
import 'moment/locale/it'; // Import Italian locale for moment

const MtInvioMessaggi = () => {
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("");
    const [filter, setFilter] = useState('freelance');
    const [showNoCorsiFuturi, setShowNoCorsiFuturi] = useState(true);
    const [emailChecked, setEmailChecked] = useState(true);
    const [smsChecked, setSmsChecked] = useState(true);
    const [appNotificationChecked, setAppNotificationChecked] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const tableRef = useRef(null);

    const defaultMessage = "Ciao da Hocus&Lotus! Non hai ancora caricato la tua offerta corsi. Fallo ora, clicca qui: https://www.hocus-lotus.edu/mt.php?t={0000000000000000000000000000000}";

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('datahandler/teachers-no-corsi-futuri', {
                token,
                filter,
                showNoCorsiFuturi,
                email: emailChecked,
                sms: smsChecked,
                appNotification: appNotificationChecked
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [filter, showNoCorsiFuturi, emailChecked, smsChecked, appNotificationChecked]);

    useEffect(() => {
        if (showNoCorsiFuturi) {
            setMessage(defaultMessage);
        } else {
            setMessage("");
        }
    }, [showNoCorsiFuturi]);

    const handleSend = async () => {
        const selectedData = data.filter(row => row.sollecitoChecked);

        if (selectedData.length > 0) {
            const solleciti = selectedData.map(sollecito => ({
                ...sollecito,
                message: message,
                email: emailChecked,
                sms: smsChecked,
                appNotification: appNotificationChecked
            }));

            if (smsChecked && message.length > 160) {
                Swal.fire('Attenzione', 'Il messaggio di sollecito non può superare i 160 caratteri.', 'warning');
                return;
            }

            try {
                const token = localStorage.getItem('token');

                const response = await axios.post('datahandler/send-solleciti', {
                    token,
                    solleciti
                });

                Swal.fire('Successo', 'Messaggi inviati con successo.', 'success');
                fetchData();
            } catch (error) {
                Swal.fire('Errore', 'Errore durante l\'invio dei messaggi.', 'error');
            }
        } else {
            Swal.fire('Attenzione', 'Nessun messaggio selezionato.', 'warning');
        }
    };

    const handleCheckboxChange = (e, cell) => {
        const checked = e.target.checked;
        cell.setValue(checked);
    };

    const handleSelectAllChange = (e) => {
        const checked = e.target.checked;
        setSelectAllChecked(checked);
        setData(prevData => prevData.map(item => ({ ...item, sollecitoChecked: checked })));
    };

    const dateFormatter = (cell) => {
        const value = cell.getValue();
        return value ? moment(value).format('DD/MM/YYYY HH:mm') : '';
    };

    const appInstalledFormatter = (cell) => {
        const installDate = cell.getValue();
        const lastUseDate = cell.getRow().getData().datautilizzo;
        const now = moment();
        const lastUseMoment = moment(lastUseDate);
        const installMoment = moment(installDate);
        const twoWeeksAgo = now.subtract(2, 'weeks');

        let icon = '';
        let tooltip = '';

        if (installDate && installMoment.isValid()) {
            if (!lastUseDate || !lastUseMoment.isValid() || lastUseMoment.isBefore(twoWeeksAgo)) {
                icon = '<img src="img/Bullet-red.png" alt="Red Bullet" style="width:32px;height:32px;"/>'; // Red bullet
                tooltip = 'App non installata o non attiva';
            } else {
                icon = '<img src="img/Bullet-green.png" alt="Green Bullet" style="width:32px;height:32px;"/>'; // Green bullet
                tooltip = `Installata il ${installMoment.format('DD/MM/YYYY HH:mm')}`;
            }
        } else {
            icon = '<img src="img/Bullet-red.png" alt="Red Bullet" style="width:32px;height:32px;"/>'; // Red bullet
            tooltip = 'App non installata o non attiva';
        }

        return `<span title="${tooltip}">${icon}</span>`;
    };

    const columns = [
        {
            title: 'Select', field: 'sollecitoChecked', hozAlign: 'center', headerSort: false, formatter: (cell) => {
                const checked = cell.getValue();
                const checkbox = document.createElement("input");
                checkbox.setAttribute("type", "checkbox");
                checkbox.checked = checked;
                checkbox.addEventListener("change", (e) => handleCheckboxChange(e, cell));
                return checkbox;
            }
        },
        { title: 'Nome', field: 'first_name', headerFilter: 'input' },
        { title: 'Cognome', field: 'last_name', headerFilter: 'input' },
        { title: 'Email', field: 'user_email', headerFilter: 'input' },
        { title: 'Telefono', field: 'billing_phone', headerFilter: 'input' },
        { title: 'Ultimo Messaggio', field: 'ultimosollecito', headerFilter: 'input', formatter: dateFormatter },
        { title: 'Messaggio', field: 'sollecito', headerFilter: 'input' },
        { title: 'Tipo teach', field: 'tipoteach', headerFilter: 'input' },
        { title: 'App installata', field: 'datainstallazione', headerFilter: 'none', formatter: appInstalledFormatter },
        { title: 'App utilizzata', field: 'datautilizzo', headerFilter: 'none', formatter: dateFormatter },
    ];

    const tableOptions = {
        height: "900px",
        selectable: true,
        virtualDom: true,
        virtualDomBuffer: 300,
        selectableRowsRangeMode: "click",
        rowSelected: function (row) {
            setSelectedRows(prevRows => [...prevRows, row.getData()]);
        },
        rowDeselected: function (row) {
            setSelectedRows(prevRows => prevRows.filter(r => r.user_id !== row.getData().user_id));
        }
    };
    return (
        <Layout>
            <Box sx={{ width: '100%', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                <Grid container spacing={2} sx={{ marginBottom: 2, maxWidth: '1300px' }}>
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ marginBottom: 2, boxShadow: 3,  maxWidth: '650px' }}>
                            <CardContent>
                                <FormLabel sx={{ marginRight: 2 }} component="legend">Mostra Magic teachers: </FormLabel><br />
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 , maxWidth: '650px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showNoCorsiFuturi}
                                                onChange={(e) => setShowNoCorsiFuturi(e.target.checked)}
                                            />
                                        }
                                        label="Senza corsi futuri caricati"
                                    />
                                    <FormControl component="fieldset" sx={{ marginRight: 2 , maxWidth: '650px'}}>
                                        <RadioGroup
                                            row
                                            aria-label="filter"
                                            name="filter"
                                            value={filter}
                                            onChange={(e) => setFilter(e.target.value)}
                                        >
                                            <FormControlLabel value="all" control={<Radio />} label="Tutte" />
                                            <FormControlLabel value="freelance" control={<Radio />} label="Freelance" />
                                            <FormControlLabel value="dipendenti" control={<Radio />} label="Dipendenti" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectAllChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            }
                                            label="Seleziona Tutte"
                                        />
                                        <Typography variant="body1" sx={{ marginLeft: 2 }}>
                                            Totale Magic Teachers: {data.length}
                                        </Typography>
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
    
                    <Grid item xs={12} lg={6}>
                        <Card sx={{ marginBottom: 0, boxShadow: 3, maxWidth: '650px' }}>
                            <CardContent>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0 , maxWidth: '650px'}}>
                                  
                                    <TextField
                                        id="message-textfield"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ maxWidth: '100%' }} // Adatta la larghezza del TextField
                                        helperText={smsChecked ? `${message.length}/160` : ''}
                                        InputProps={{
                                            sx: { fontSize: 12 } // Imposta la dimensione del carattere qui
                                        }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 0 , maxWidth: '650px'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={emailChecked}
                                                onChange={(e) => setEmailChecked(e.target.checked)}
                                            />
                                        }
                                        label="Email"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={smsChecked}
                                                onChange={(e) => setSmsChecked(e.target.checked)}
                                            />
                                        }
                                        label="SMS"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={appNotificationChecked}
                                                onChange={(e) => setAppNotificationChecked(e.target.checked)}
                                                disabled
                                            />
                                        }
                                        label="Notifica app"
                                    />
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSend}
                                        sx={{ marginLeft: 2, marginRight: 5 }}
                                    >
                                        Invia Messaggi
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
    
                <Box sx={{ flexGrow: 1, width: '100%', marginTop: 2,  }}>
                    <ReactTabulator
                        ref={tableRef}
                        data={data}
                        columns={columns}
                        layout="fitData"
                        options={tableOptions}
                        style={{ width: '100%' }}
                    />
                </Box>
            </Box>
        </Layout>
    );
    
        
    
};

export default MtInvioMessaggi;
