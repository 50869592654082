import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import axios from '../axios';

const Notifiche = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSendNotification = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/push/send-notification', {
                title: 'PROVA',
                message:message,    
                token: token
            });
            if (response.data.success) {
                setSuccess('Notifica inviata con successo!');
                setError('');
                setMessage('');
            } else {
                setError(response.data.error || 'Errore durante l\'invio della notifica.');
                setSuccess('');
            }
        } catch (error) {
            setError('Errore durante l\'invio della notifica.');
            setSuccess('');
        }
    };

    return (
        <Box mt={2}>
            <Typography variant="h4" gutterBottom>
                Invia una Notifica
            </Typography>
            <TextField
                label="Messaggio"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ marginBottom: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSendNotification}>
                Invia
            </Button>
            {error && (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                    {error}
                </Alert>
            )}
            {success && (
                <Alert severity="success" sx={{ marginTop: 2 }}>
                    {success}
                </Alert>
            )}
        </Box>
    );
};

export default Notifiche;
