// src/api.js
import axios from './axios';

export const fetchBarChartData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};
