// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BarChart from './BarChart';
import { fetchBarChartData } from '../api';
import Layout from './Layout';

const Dashboard = () => {
    const [ChartData1, setChartData1] = useState({ labels: [], datasets: [] });
    const [ChartData2, setChartData2] = useState({ labels: [], datasets: [] });
    const [ChartData3, setChartData3] = useState({ labels: [], datasets: [] });
    const [ChartData4, setChartData4] = useState({ labels: [], datasets: [] });
    const [ChartData5, setChartData5] = useState({ labels: [], datasets: [] });

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const Data1 = await fetchBarChartData('/graph/teachers');
                const Data2 = await fetchBarChartData('/graph/corsitot');
                const Data3 = await fetchBarChartData('/graph/alunnitot');
                const Data4 = await fetchBarChartData('/graph/alunnitorta');
                const Data5 = await fetchBarChartData('/graph/prezzimediperregione');

                setChartData1(processData1(Data1));
                setChartData2(processData2(Data2));
                setChartData3(processData3(Data3));
                setChartData4(processData4(Data4));
                setChartData5(processData5(Data5));
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                }
            }
        };
        fetchData();
    }, [navigate]);

    const processData1 = (data) => {
        const labels = data.map(item => item.meta_value);
        const dataset = data.map(item => item.numteachers);

        return {
            labels,
            datasets: [{
                label: 'Teachers',
                data: dataset,
                backgroundColor: '#FF6384'
            }]
        };
    };

    const processData2 = (data) => {
        const labels = [...new Set(data.map(item => item.course_type))];
        const courseStatus = [...new Set(data.map(item => item.course_status))];

        const datasets = courseStatus.map(status => ({
            label: status,
            data: labels.map(label => {
                const course = data.find(item => item.course_status === status && item.course_type === label);
                return course ? course.num_courses : 0;
            }),
            backgroundColor: status === 'Corsi attivi' ? '#36A2EB' : '#FF6384'
        }));

        return {
            labels,
            datasets,
        };
    };

    const processData3 = (data) => {
        const labels = ['Alunni Passati', 'Alunni Attivi', 'Alunni Futuri'];
        const dataset = data.length ? [data[0].alunni_passati, data[0].alunni_attivi, data[0].alunni_futuri] : [];

        return {
            labels,
            datasets: [{
                label: 'Dinoalunni',
                data: dataset,
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }]
        };
    };

    const processData4 = (data) => {
        const labels = ['Associati', 'Attivi', 'Attivi recentemente'];
        const dataset = data.length ? [data[0].registrati, data[0].loggati, data[0].loggatirecentemente] : [];

        return {
            labels,
            datasets: [{
                label: 'Attività Dinoalunni',
                data: dataset,
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }]
        };
    };

    const processData5 = (data) => {
        const labels = Object.keys(data);
        const dataset = Object.values(data);

        return {
            labels,
            datasets: [{
                label: 'Prezzo Medio per Regione',
                data: dataset,
                backgroundColor: dataset.map((_, index) => ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'][index % 7])
            }]
        };
    };

    return (
        <Layout>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Magic Teachers Attive
                    </Typography>
                    <Box sx={{ height: 400 }}>
                        <BarChart
                            title="Magic Teachers Attive"
                            labels={ChartData1.labels}
                            datasets={ChartData1.datasets}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Corsi Caricati
                    </Typography>
                    <Box sx={{ height: 400 }}>
                        <BarChart
                            title="Corsi Caricati"
                            labels={ChartData2.labels}
                            datasets={ChartData2.datasets}
                            displayLegend={true}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Dinoalunni
                    </Typography>
                    <Box sx={{ height: 400 }}>
                        <BarChart
                            title="Dinoalunni"
                            labels={ChartData3.labels}
                            datasets={ChartData3.datasets}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Attività Dinoalunni
                    </Typography>
                    <Box sx={{ height: 400 }}>
                        <BarChart
                            title="Attività Dinoalunni"
                            labels={ChartData4.labels}
                            datasets={ChartData4.datasets}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="h6" gutterBottom>
                        Prezzo Medio per Regione
                    </Typography>
                    <Box sx={{ height: 400 }}>
                        <BarChart
                            title="Prezzo Medio per Regione"
                            labels={ChartData5.labels}
                            datasets={ChartData5.datasets}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Dashboard;
