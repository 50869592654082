// src/components/CorsiCaricati.js
import React, { useState } from 'react';
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';
import Layout from './Layout';
import CorsiCaricatiTable from './CorsiCaricatiTable';

const CorsiCaricati = () => {
    const [subtype, setSubtype] = useState('attuali');

    const handleSwitchChange = () => {
        setSubtype((prev) => (prev === 'attuali' ? 'futuri' : 'attuali'));
    };

    return (
        <Layout>
            {/* <Typography variant="h4" gutterBottom>
                Corsi Caricati
            </Typography> */}
            <FormControlLabel
                control={<Switch checked={subtype === 'futuri'} onChange={handleSwitchChange} color="primary" />}
                label="Mostra Corsi Futuri"
            />
            <Box sx={{ maxWidth: '100%' }}>
                <CorsiCaricatiTable subtype={subtype} />
            </Box>
        </Layout>
    );
};

export default CorsiCaricati;
