import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from '../axios';
import Swal from 'sweetalert2';
import './SweetAlertCustom.css';  // Import the custom CSS for SweetAlert

const TemplateEmailEditor = ({ open, handleClose, handleSave, templateId, fetchEmailTemplates, selectedLevel }) => {
    const [messageType, setMessageType] = useState('');
    const [subject, setSubject] = useState('');
    const [code, setCode] = useState('');
    const [immagine, setImmagine] = useState('');
    const [stepNumber, setStepNumber] = useState('');
    const [totalSteps, setTotalSteps] = useState('');
    const [content, setContent] = useState('');
    const [testEmail, setTestEmail] = useState('');  // Stato per l'indirizzo email di prova

    useEffect(() => {
        if (templateId) {
            // Fetch the existing template data
            axios.get(`/anagrafiche/get_template?id=${templateId}`, {
                withCredentials: true,
            })
            .then(response => {
                const template = response.data;
                // Determine messageType based on API response
                if (template.sendBenvenuto) {
                    setMessageType('Benvenuto');
                } else if (template.sendFine) {
                    setMessageType('Fine');
                } else if (template.sendRoadMap) {
                    setMessageType('RoadMap');
                    const steps = template.sendRoadMap.split('/');
                    setStepNumber(steps[0] || '');
                    setTotalSteps(steps[1] || '');
                }

                setSubject(template.subject);
                setCode(template.codice);
                setImmagine(template.immagine);
                setContent(template.htmlContent);
            })
            .catch(error => {
                console.error('Error fetching template:', error);
            });
        } else {
            // Reset fields if no templateId is provided (i.e., adding new template)
            setMessageType('');
            setSubject('');
            setCode('');
            setImmagine('');
            setStepNumber('');
            setTotalSteps('');
            setContent('');
        }
    }, [templateId]);

    useEffect(() => {
        // Rimuove il tabindex dall'elemento dialog di TinyMCE
        const removeTabindex = () => {
            const dialogContainer = document.querySelector('.MuiDialog-container[tabindex="-1"]');
            if (dialogContainer) {
                dialogContainer.removeAttribute('tabindex');
            }
        };

        // Inizialmente rimuove il tabindex
        removeTabindex();

        // Rimuove il tabindex ogni volta che il dialogo viene aperto
        if (open) {
            const observer = new MutationObserver(removeTabindex);
            observer.observe(document, { childList: true, subtree: true });
            return () => observer.disconnect();
        }
    }, [open]);

    const handleSaveClick = async () => {
        const token = localStorage.getItem('token'); // Get the token from local storage
        const newTemplate = {
            id: templateId,
            idLivello: selectedLevel,
            codice: code,
            immagine: immagine,
            subject,
            htmlContent: content,
            sendBenvenuto: messageType === 'Benvenuto' ? 1 : null,
            sendFine: messageType === 'Fine' ? 1 : null,
            sendRoadMap: messageType === 'RoadMap' ? `${stepNumber}/${totalSteps}` : null,
        };

        try {
            const response = await axios({
                method: 'post',
                url: 'datahandler/upsert-template',
                data: {
                    token: token,
                    template: newTemplate
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            });

            if (response.data.success) {
                Swal.fire(
                    'Successo!',
                    'Il template è stato salvato con successo.',
                    'success'
                );
                handleSave();
                handleClose();
            } else {
                Swal.fire(
                    'Errore!',
                    `Si è verificato un errore durante il salvataggio del template: ${response.data.error}`,
                    'error'
                );
            }
        } catch (error) {
            console.error('Error saving template:', error);
            Swal.fire(
                'Errore!',
                'Si è verificato un errore durante il salvataggio del template.',
                'error'
            );
        }
    };

    const handleSendTestEmail = async () => {
        const token = localStorage.getItem('token'); // Get the token from local storage
        try {
            const response = await axios({
                method: 'post',
                url: 'datahandler/testemail-template',
                data: {
                    token: token,
                    email: testEmail,
                    template: {
                        id: templateId,
                        idLivello: selectedLevel,
                        codice: code,
                        immagine: immagine,
                        subject,
                        htmlContent: content,
                        sendBenvenuto: messageType === 'Benvenuto' ? 1 : null,
                        sendFine: messageType === 'Fine' ? 1 : null,
                        sendRoadMap: messageType === 'RoadMap' ? `${stepNumber}/${totalSteps}` : null,
                    }
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            });

            if (response.data.success) {
                Swal.fire(
                    'Successo!',
                    'L\'email di prova è stata inviata con successo.',
                    'success'
                );
            } else {
                Swal.fire(
                    'Errore!',
                    `Si è verificato un errore durante l'invio dell'email di prova: ${response.data.error}`,
                    'error'
                );
            }
        } catch (error) {
            console.error('Error sending test email:', error);
            Swal.fire(
                'Errore!',
                'Si è verificato un errore durante l\'invio dell\'email di prova.',
                'error'
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{templateId ? 'Modifica Template' : 'Aggiungi Template'}</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    select
                    label="Tipo di messaggio"
                    value={messageType}
                    onChange={(e) => setMessageType(e.target.value)}
                    margin="normal"
                >
                    <MenuItem value="Benvenuto">Benvenuto</MenuItem>
                    <MenuItem value="Fine">Fine</MenuItem>
                    <MenuItem value="RoadMap">RoadMap</MenuItem>
                </TextField>
                {messageType === 'RoadMap' && (
                    <>
                        <TextField
                            fullWidth
                            label="Step n."
                            type="number"
                            value={stepNumber}
                            onChange={(e) => setStepNumber(e.target.value)}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Totale step della roadmap"
                            type="number"
                            value={totalSteps}
                            onChange={(e) => setTotalSteps(e.target.value)}
                            margin="normal"
                        />
                    </>
                )}
                <TextField
                    fullWidth
                    label="Codice"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    margin="normal"
                />

                <TextField
                    fullWidth
                    label="Immagine testata"
                    value={immagine}
                    onChange={(e) => setImmagine(e.target.value)}
                    margin="normal"
                />


                <TextField
                    fullWidth
                    label="Oggetto del messaggio email"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    margin="normal"
                />
                <Editor
                    apiKey="6xmadbekbnzfea7pxt1vko2jmssu8ywah31a2bg8v2a2u1hf"
                    value={content}
                    init={{
                        height: 300,
                        menubar: false,
                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                        toolbar: [
                            'undo redo | blocks fontfamily fontsize | bold italic underline | forecolor backcolor | link image media table',
                            'align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code'
                        ],
                        file_picker_types: 'image',
                        file_picker_callback: function(callback, value, meta) {
                            // Ritorna direttamente senza azione per disabilitare il file picker
                            return;
                          }
                    }}
                    onEditorChange={(newContent) => setContent(newContent)}
                />
               
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="info">
                    Chiudi
                </Button>
              
   <Button onClick={handleSendTestEmail}   variant="contained"
                                color="primary">
                    Invia email di prova
                </Button>
         
 <TextField
                    
                    label="Indirizzo email di prova"
                    value={testEmail}
                    onChange={(e) => setTestEmail(e.target.value)}
                    margin="normal"
                />
             

       <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveClick}
                                style={{ marginRight: '10px' }}
                            >
                                Salva
                            </Button>



            </DialogActions>
        </Dialog>
    );
};

export default TemplateEmailEditor;
