import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Switch, Tooltip, Snackbar, Alert, Button } from '@mui/material';
import axios from '../axios';
import RescheduleDialog from './RescheduleDialog'; // Import the reschedule dialog component
import dayjs from 'dayjs'; // Import dayjs for date handling

const PresenzeDinoalunniDetail = ({ idCorso, dataLezione, onSaveSuccess }) => {
    const [students, setStudents] = useState([]);
    const [idLezione, setIdLezione] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
    const [showDatePicker, setShowDatePicker] = useState(false); // State to show or hide the reschedule dialog
    const [selectedDate, setSelectedDate] = useState(dayjs(dataLezione)); // State for the selected date

    const fetchStudents = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/datahandler/presenze-insegnante', {
                token,
                idCorso,
                dataLezione
            });
            setStudents(response.data.map(student => ({
                ...student,
                presente: true // Initially mark all students as present
            })));
            if (response.data.length > 0) {
                setIdLezione(response.data[0].idLezione); // Assume all rows have the same idLezione
            }
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    useEffect(() => {
        fetchStudents();
    }, [idCorso, dataLezione]);

    const handleReschedule = () => {
        setShowDatePicker(true); // Show the reschedule dialog
    };

    const handleSaveDate = async (newDate) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/datahandler/reschedule-lezione', {
                token,
                idLezione,
                nuovaData: newDate.format('YYYY-MM-DD') // Pass the formatted new date to the endpoint
            });
            setSelectedDate(newDate); // Update the selected date
            setShowDatePicker(false); // Close the dialog
            setSnackbarMessage('Lezione ripianificata con successo!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            fetchStudents(); // Reload data after rescheduling
            onSaveSuccess(); // Notify success
        } catch (error) {
            console.error('Error rescheduling lezione:', error);
            setSnackbarMessage('Errore nella ripianificazione della lezione.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleAttendanceToggle = (idAlunno) => {
        setStudents((prevStudents) =>
            prevStudents.map((student) =>
                student.idAlunno === idAlunno
                    ? { ...student, presente: !student.presente }
                    : student
            )
        );
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/datahandler/save-presenze', {
                token,
                idLezione,
                students: students.map(student => ({
                    idAlunno: student.idAlunno,
                    presente: student.presente ? 1 : 0
                }))
            });
            setSnackbarMessage('Presenze salvate con successo!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            onSaveSuccess(); // Call the callback to notify success
        } catch (error) {
            console.error('Error saving presenze:', error);
            setSnackbarMessage('Errore nel salvataggio delle presenze.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ width: '100%', mx: 'auto', px: 1  }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Dettaglio Presenze per la lezione del {dayjs(dataLezione).format('DD/MM/YYYY')}
                </Typography>
                <Box>
                    <Tooltip title="Ripianifica la data della lezione">
                        <Button variant="contained" color="secondary" onClick={handleReschedule} sx={{ marginRight: 1 }}>
                            Ripianifica
                        </Button>
                    </Tooltip>
                    <Tooltip title="Salva presenze dinoalunni">
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Salva
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
            {students.length === 0 ? (
                <Typography variant="body1">Nessun alunno trovato.</Typography>
            ) : (
                students.map((student) => (
                    <Card key={student.idAlunno} sx={{ marginBottom: 1 }}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between', // Align items with space between
                                backgroundColor: student.presente ? 'transparent' : 'salmon',
                                padding: '8px'
                            }}
                        >
                            <Typography
                                sx={{
                                    textDecoration: student.presente ? 'none' : 'line-through',
                                    flexGrow: 1 // Allow text to take available space
                                }}
                            >
                                {student.first_name} {student.last_name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Switch
                                    checked={student.presente}
                                    onChange={() => handleAttendanceToggle(student.idAlunno)}
                                    color="primary"
                                />
                                <Typography variant="body2">
                                    {student.presente ? 'Presente' : 'Assente'}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                ))
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Dialog for rescheduling */}
            <RescheduleDialog
                showDatePicker={showDatePicker}
                setShowDatePicker={setShowDatePicker}
                initialDate={selectedDate}
                handleSaveDate={handleSaveDate}
            />
        </Box>
    );
};

export default PresenzeDinoalunniDetail;
