import axios from 'axios';


// Creare un'istanza Axios
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,  // Base URL delle tue API
    withCredentials: true,  // Includi i cookie con ogni richiesta
    headers: {
        'Content-Type': 'application/json',
    },
});

// Aggiungi un interceptor per includere il token in ogni richiesta
apiClient.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default apiClient;