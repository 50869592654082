import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from './axios';

const AuthContext = createContext();

// Configura Axios per inviare i cookie
axios.defaults.withCredentials = true;

export function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token') || '');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      localStorage.setItem('token', token);
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('/auth/login', { username, password });
      if (response.data.status === 'success') {
        const token = response.data.token;
        
        localStorage.setItem('token', token);
        setAuthToken(token);
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error('Login error:', error);
      return { status: 'error', message: 'Login failed' };
    }
  };

  const logout = () => {
    
    localStorage.removeItem('token');
    setAuthToken('');
  };

  const getToken = () => authToken;

  return (
    <AuthContext.Provider value={{ login, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
