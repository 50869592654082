import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Card, CardContent, Avatar, useMediaQuery, Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './Notifications.css';  // Import the CSS file

import CloseIcon from '@mui/icons-material/Close'; // Icona di chiusura standard
import NotificationsIcon from '@mui/icons-material/Notifications'; // Icona per la notifica
import axios from '../axios';
import Layout from './Layout';
import moment from 'moment';
import 'moment/locale/it'; // Importa il locale italiano

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const theme = useTheme(); // Usa useTheme per accedere al tema

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Verifica se lo schermo è piccolo

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post('/datahandler/notifications', {
                    token
                });
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, []);

    const handleRemoveNotification = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`/datahandler/notifications/viewed`, {
                id,
                token
            });
            setNotifications(notifications.filter(notification => notification.id !== id));
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };

    return (
        <Layout>
            {notifications.length === 0 ? (
                <Typography variant="body1">Nessuna notifica in arrivo.</Typography>
            ) : (
                isSmallScreen ? (
                    <Box sx={{ width: '100%', maxWidth: 300, mx: 'auto', px: 1 }}>
                        <Typography variant="h5" gutterBottom>
                            Ciao Magic Teacher! Hai delle Notifiche:
                        </Typography>
                        {notifications.map(notification => (
                            <Card key={notification.id} sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d0e4fe', color: '#000000', marginBottom: 2, borderRadius: 4, boxShadow: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px' }}>
                                    <IconButton onClick={() => handleRemoveNotification(notification.id)} sx={{ color: '#000000' }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Badge badgeContent={moment(notification.created_at).format('DD/MM/YYYY')} color="success" />
                                    <Avatar sx={{ bgcolor: 'orange', marginRight: 1 }}>
                                        <NotificationsIcon />
                                    </Avatar>
                                </Box>
                                <CardContent sx={{ flex: 1, padding: '8px' }}>
                                    <Typography variant="body2" sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: notification.message }} />
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ width: '100%', mx: 'auto', px: 1 }}>
                    <Typography variant="h4" gutterBottom>
                        Ciao Magic Teacher! Hai delle Notifiche:
                    </Typography>
                    {notifications.map(notification => (
                        <Card key={notification.id} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#d0e4fe', color: '#000000', marginBottom: 2, borderRadius: 2, boxShadow: 1 }}>
                            <IconButton onClick={() => handleRemoveNotification(notification.id)} sx={{ color: '#000000' }}>
                                <CloseIcon />
                            </IconButton>
                            <CardContent sx={{ flex: 1, padding: '8px', paddingLeft: '16px', position: 'relative' }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    Promemoria per te
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1, minHeight:48 }} dangerouslySetInnerHTML={{ __html: notification.message }} />
                                <Typography variant="caption" sx={{ position: 'absolute', top: 8, right: 8, color: '#000000' }}>
                                <Badge badgeContent={moment(notification.created_at).format('DD/MM/YYYY')} color="success" sx={{ marginRight: 1 }} />

                                </Typography>
                            </CardContent>
                            <Avatar sx={{ bgcolor: 'orange', margin: 1 }}>
                                <NotificationsIcon />
                            </Avatar>
                        </Card>
                    ))}
                </Box>
                
                )
            )}
        </Layout>
    );
};

export default Notifications;
