import React, { useState } from 'react';
import { Box, Modal, Button, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la lingua italiana

const RescheduleDialog = ({ showDatePicker, setShowDatePicker, initialDate, handleSaveDate }) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleClose = () => {
    setShowDatePicker(false);
  };

  return (
    <Modal
      open={showDatePicker}
      onClose={handleClose}
      aria-labelledby="reschedule-dialog-title"
      aria-describedby="reschedule-dialog-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          zIndex: 1300,
        }}
      >
        <Typography id="reschedule-dialog-title" variant="h6" component="h2">
          Ripianifica la Lezione
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </LocalizationProvider>
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={() => handleSaveDate(selectedDate)} sx={{ mr: 1 }}>
            Salva
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annulla
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RescheduleDialog;
