import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './auth';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import CorsiCaricati from './components/CorsiCaricati';
import MtInvioMessaggi from './components/MtInvioMessaggi';
import MagicTeachersQueue from './components/MagicTeachersQueue';
import TemplateEmail from './components/TemplateEmail';
import CorsiInsegnante from './components/CorsiInsegnante';
import Notifiche from './components/Notifiche'; 
import MtAuth from './components/MtAuth';
import Logout from './components/Logout';
import PresenzeDinoalunni from './components/PresenzeDinoalunni'; 
import MieiDinoalunni from './components/MieiDinoAlunni'; 
import AreaDownload from './components/AreaDownload'; 
import Notifications from './components/Notifications';
import { Snackbar, Button } from '@mui/material';
import dotenv from 'dotenv';
import GlobalRoadmap from './components/GlobalRoadmap';

dotenv.config();

const App = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [token, setToken] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [installPromptVisible, setInstallPromptVisible] = useState(false);
    const [updateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setInstallPromptVisible(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            const token = localStorage.getItem('token');
            if (token) {
                setToken(token);
                checkNotificationPermission();
            }

            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.log('Service Worker registered:', registration);

                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                if (navigator.serviceWorker.controller) {
                                    // Nuovo contenuto è disponibile; chiedi all'utente di ricaricare.
                                    setUpdateAvailable(true);
                                }
                            }
                        };
                    };
                })
                .catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const checkNotificationPermission = () => {
        if (Notification.permission === 'default') {
            setSnackbarOpen(true);
        } else {
            setSnackbarOpen(false);
        }
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                setInstallPromptVisible(false);
            });
        }
    };

    const handleEnableNotifications = () => {
        askPermission(token);
    };

    const askPermission = async (authToken) => {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            subscribeUserToPush(authToken);
        } else {
            console.log('Notification permission denied.');
            setSnackbarOpen(false);  
        }
    };

    const subscribeUserToPush = async (authToken) => {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BMrfMCcebCSHl0fqTNswgw9znFHHJDP7UzPGLR2KDGyaRdx89tv5SDBe7cxik5CVkfZmJKqBMlUExDOslS8wJmY')
        });
        console.log('User is subscribed:', subscription);
        sendSubscriptionToServer(subscription, authToken);
    };

    const urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    const sendSubscriptionToServer = async (subscription, authToken) => {
        const { endpoint, options: { applicationServerKey: p256dh } = {} } = subscription;
        console.log('Subscription details:', subscription);
        console.log('Auth token:', authToken);

        if (!endpoint) {
            console.error('Missing endpoint');
            return;
        }
        if (!p256dh) {
            console.error('Missing keys');
            return;
        }
        if (!authToken) {
            console.error('Missing token');
            return;
        }
        const arrayBufferToBase64 = (buffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        };

        const p256dhKey = arrayBufferToBase64(p256dh);
        const contentEncoding = subscription.options.applicationServerKey.constructor.name;

        const response = await fetch('https://app.hocus-lotus.edu/papi/push/save-subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                endpoint: endpoint,
                keys: {
                    p256dh: p256dhKey,
                },
                contentEncoding: contentEncoding,
                user_token: authToken,
            }),
        });

        if (!response.ok) {
            console.error('Failed to save subscription on server');
        } else {
            console.log('Subscription saved on server');
            setSnackbarOpen(false);  // Nascondi la Snackbar quando la sottoscrizione è salvata con successo
        }
    };

    const handleUpdate = () => {
        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
    };

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<ProtectedRoute requiredPermissions={['dashboard', 'all']}><Dashboard /></ProtectedRoute>} />
                    <Route path="/corsi-caricati" element={<ProtectedRoute requiredPermissions={['corsi-caricati', 'all']}><CorsiCaricati /></ProtectedRoute>} />
                    <Route path="/globalroadmap" element={<ProtectedRoute requiredPermissions={['corsi-caricati', 'all']}><GlobalRoadmap /></ProtectedRoute>} />
                    <Route path="/mt-inviomessaggi" element={<ProtectedRoute requiredPermissions={['mt-inviomessaggi', 'all']}><MtInvioMessaggi /></ProtectedRoute>} />
                    <Route path="/magic-teachers-queue" element={<ProtectedRoute requiredPermissions={['magic-teachers-queue', 'all']}><MagicTeachersQueue /></ProtectedRoute>} />
                    <Route path="/notifiche" element={<ProtectedRoute requiredPermissions={['magic-teachers-queue', 'all']}><Notifiche /></ProtectedRoute>} />
                    <Route path="/template-email" element={<ProtectedRoute requiredPermissions={['template-email', 'all']}><TemplateEmail /></ProtectedRoute>} />
                    <Route path="/corsi-insegnante" element={<ProtectedRoute requiredPermissions={['corsi-insegnante', 'all']}><CorsiInsegnante /></ProtectedRoute>} />
                    <Route path="/presenze-dinoalunni" element={<ProtectedRoute requiredPermissions={['corsi-insegnante', 'all']}><PresenzeDinoalunni /></ProtectedRoute>} /> 
                    <Route path="/miei-dinoalunni" element={<ProtectedRoute requiredPermissions={['corsi-insegnante', 'all']}><MieiDinoalunni /></ProtectedRoute>} /> 
                    <Route path="/areadownload" element={<ProtectedRoute requiredPermissions={['corsi-insegnante', 'all']}><AreaDownload /></ProtectedRoute>} /> 
                    <Route path="/notifications" element={<ProtectedRoute requiredPermissions={['corsi-insegnante', 'all']}><Notifications /></ProtectedRoute>} />
                    <Route path="/mtauth" element={<MtAuth />} />
                    <Route path="/logout" element={<Logout />} /> {/* Aggiungi la rotta per il logout */}
                </Routes>
            </Router>
            {installPromptVisible && (
                <Button onClick={handleInstallClick} style={{ position: 'fixed', bottom: 16, right: 16 }} variant="contained" color="primary">
                    Installa l'App Hocus & Lotus su questo dispositivo
                </Button>
            )}
            <Snackbar
                open={snackbarOpen}
                message="Abilita le notifiche per l'App Hocus & Lotus"
                action={
                    <Button sx={{ color: '#006F00', backgroundColor: '#FFA500', '&:hover': { backgroundColor: '#FFA500', color:'#FFFFFF' } }} size="small" onClick={handleEnableNotifications}>
                        Abilita
                    </Button>
                }
            />
            <Snackbar
                open={updateAvailable}
                message="Una nuova versione è disponibile. Clicca per aggiornare."
                action={
                    <Button sx={{ color: '#006F00', backgroundColor: '#FFA500', '&:hover': { backgroundColor: '#FFA500', color:'#FFFFFF' } }} size="small" onClick={handleUpdate}>
                        Aggiorna
                    </Button>
                }
            />
        </AuthProvider>
    );
};

function ProtectedRoute({ children, requiredPermissions }) {
    const token = localStorage.getItem('token');
    const permissionsString = localStorage.getItem('permissions');
    let permissions = [];

    if (permissionsString) {
        try {
            permissions = JSON.parse(permissionsString);
        } catch (error) {
            console.error('Error parsing permissions:', error);
            permissions = [];
        }
    }

    const canAccess = (permission) => {
        if (permission === 'corsi-insegnante') return true;
        if (permissions.includes('all')) return true;
        return permissions.includes(permission);
    };

    const hasPermission = requiredPermissions.some(canAccess);

    if (!token) {
        return <Navigate to="/" />;
    }

    if (!hasPermission) {
        return <Navigate to="/notifications" />;
    }

    return children;
}

export default App;
