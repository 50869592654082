import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import axios from '../axios';
import MedalIcon from '@mui/icons-material/MilitaryTech'; // Icona della medaglia

const Valutazioni = ({ idAlunno }) => {
    const [valutazioni, setValutazioni] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchValutazioni = async () => {
            try {
                const response = await axios.post('datahandler/corso-alunno-valutazioni', {
                    idAlunno: idAlunno,
                    token: token
                });
                setValutazioni(response.data);
            } catch (error) {
                console.error('Error fetching valutazioni:', error);
            }
        };

        fetchValutazioni();
    }, [idAlunno]);

    return (
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary">Valutazioni:</Typography>
            <Grid container spacing={2} mt={1}>
                {valutazioni.map((valutazione, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ backgroundColor: '#FFF3E0' }}>
                            <CardContent>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <MedalIcon sx={{ fontSize: 40, color: '#FFB74D' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                                            {`${valutazione.dat.substring(6, 8)}/${valutazione.dat.substring(4, 6)}/${valutazione.dat.substring(0, 4)}`}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                                            {valutazione.val}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Valutazioni;
