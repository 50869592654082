import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Typography, AppBar, Toolbar, Drawer, Button, Avatar, IconButton, useMediaQuery, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import MenuComponent from './Menu'; // Assicurati di avere il componente Menu importato correttamente
import { useTheme } from '@mui/material/styles';
const drawerWidth = 240;

const Layout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [authorized, setAuthorized] = useState(true);
    const navigate = useNavigate();
    const theme = useTheme(); // Usa useTheme per accedere al tema
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const firstName = localStorage.getItem('first_name');
        const lastName = localStorage.getItem('last_name');
        const authToken = localStorage.getItem('token');

        if (firstName && lastName && authToken) {
            setFirstName(firstName);
            setLastName(lastName);
        } else {
            setAuthorized(false);
        }
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        navigate('/logout');
    };

    if (!authorized) {
        return (
           
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" color="error" sx={{ mb: 2 }}>
                            Non autorizzato, effettuare il login.
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                            Login
                        </Button>
                    </Box>
                </Box>
           
        );
    }

    return (
      
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Avatar 
                            alt="Logo" 
                            src="https://www.hocus-lotus.edu/wp-content/themes/hocus_light-v_05_23/tema-hocus-front/img-home/corso-di-lingue-per-bambini.png.webp" 
                            sx={{ width: 34, height: 34, marginRight: 1 }}
                        />
                        <Typography variant="h6" noWrap component="div">
                            {isSmallScreen ? 'H&L app' : 'Hocus & Lotus App'}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                            {isSmallScreen ? (
                                <>
                                    <IconButton
                                        color="inherit"
                                        aria-controls="user-menu"
                                        aria-haspopup="true"
                                        onClick={handleMenuOpen}
                                    >
                                        <Avatar 
                                            alt="Bird Icon" 
                                            src="https://www.hocus-lotus.edu/wp-content/themes/hocus_light-v_05_23/tema-hocus-front/img-home/insegnare-ai-bambini.png.webp"
                                            sx={{ width: 34, height: 34 }}
                                        />
                                    </IconButton>
                                    <Menu
                                        id="user-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem>
                                            <Avatar 
                                                alt="User Icon" 
                                                src="https://www.hocus-lotus.edu/wp-content/themes/hocus_light-v_05_23/tema-hocus-front/img-home/insegnare-ai-bambini.png.webp" 
                                                sx={{ width: 34, height: 34, marginRight: 1 }}
                                            />
                                            <Typography variant="body1">
                                                {firstName} {lastName}
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <LogoutIcon />
                                            <Typography variant="body1" sx={{ ml: 1 }}>
                                                Logout
                                            </Typography>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <Avatar 
                                        alt="User Icon" 
                                        src="https://www.hocus-lotus.edu/wp-content/themes/hocus_light-v_05_23/tema-hocus-front/img-home/insegnare-ai-bambini.png.webp" 
                                        sx={{ width: 34, height: 34, marginRight: 1 }}
                                    />
                                    <Typography variant="body1" sx={{ marginRight: 2 }}>
                                        {firstName} {lastName}
                                    </Typography>
                                    <IconButton color="inherit" onClick={handleLogout}>
                                        <LogoutIcon />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <MenuComponent />
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        open={drawerOpen}
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <MenuComponent />
                    </Drawer>
                </Box>
                <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, mt: '64px' }}>
                    {children}
                </Box>
            </Box>
        
    );
};

export default Layout;
