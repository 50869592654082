// src/components/CorsiCaricatiTable.js
import React, { useEffect, useState } from 'react';
import axios from './../axios';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_simple.min.css'; // use Theme(s)
import './CorsiCaricatiTable.css'; // Import custom styles
import CourseDetailsModal from './CourseDetailsModal';

const CorsiCaricatiTable = ({ subtype }) => {
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/anagrafiche/corsi-caricati?subtype=${subtype}`, {
                    withCredentials: true,
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [subtype]);

    const handleOpenModal = (userId) => {
        setSelectedUserId(userId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedUserId(null);
    };

    const tableOptions = {
        height: "900px",
        selectable: true,
        virtualDom: true,
        virtualDomBuffer: 300,
        selectableRowsRangeMode: "click",
    };


    const columns = [
        { title: 'Nome', field: 'NomeMT', headerFilter: 'input' },
        { title: 'Cognome', field: 'CognomeMT', headerFilter: 'input' },
        { title: 'Username', field: 'user_login', headerFilter: 'input' },
        { title: 'Email', field: 'user_email', headerFilter: 'input' },
        {
            title: 'Numero Corsi', field: 'num_corsi', headerFilter: 'input', hozAlign: 'center' , formatter: (cell) => {
                const userId = cell.getRow().getData().user_id;
                const button = document.createElement('button');
                button.innerHTML = cell.getValue();
                button.className = 'course-button';
                button.onclick = () => handleOpenModal(userId);
                return button;
            }
        },
        { title: 'Numero Dinoalunni', field: 'numdinoalunni', headerFilter: 'input', formatter: 'number', hozAlign: 'right' },
    ];

    return (
        <div className="tabulator-container">
            <ReactTabulator
                data={data}
                columns={columns}
                layout="fitData"
                options={tableOptions}
            />
            {selectedUserId && (
                <CourseDetailsModal
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    userId={selectedUserId}
                    subtype={subtype}
                />
            )}
        </div>
    );
};

export default CorsiCaricatiTable;
