import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import axios from './../axios';
import Layout from './Layout';
import TemplateEmailTable from './TemplateEmailTable';
import TemplateEmailEditor from './TemplateEmailEditor';
import './TemplateEmail.css';  // Import the CSS file

const TemplateEmail = () => {
    const [livelli, setLivelli] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [editorOpen, setEditorOpen] = useState(false);
    const [templateId, setTemplateId] = useState(null);

    useEffect(() => {
        const fetchLivelli = async () => {
            try {
                const response = await axios.get('/anagrafiche/get_livelli', {
                    withCredentials: true,
                });
                setLivelli(response.data);
            } catch (error) {
                console.error('Error fetching levels:', error);
            }
        };
        fetchLivelli();
    }, []);

    const fetchEmailTemplates = async (idLivello) => {
        setLoading(true);
        try {
            const response = await axios.get(`/anagrafiche/emailtemplates?idLivello=${idLivello}`, {
                withCredentials: true,
            });
            setEmailTemplates(response.data);
            setSelectedLevel(idLivello);
        } catch (error) {
            console.error('Error fetching email templates:', error);
        }
        setLoading(false);
    };

    const handleOpenEditor = (id) => {
        setTemplateId(id);
        setEditorOpen(true);
    };

    const handleCloseEditor = () => {
        setEditorOpen(false);
    };

    const handleSaveTemplate = async (template) => {
        // Handle save logic
        // Upsert logic is now handled within TemplateEmailEditor component
    };

    return (
        <Layout>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {/* <Typography variant="h4" gutterBottom>Template Email</Typography> */}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'flex-start' }}>
                    {selectedLevel && (
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleOpenEditor(null)}
                            sx={{ marginRight: 2 }}
                        >
                            Aggiungi Template
                        </Button>
                    )}
                    {livelli.map(livello => (
                        <Button
                            key={livello.term_id}
                            variant="contained"
                            color={selectedLevel === livello.term_id ? 'primary' : 'secondary'}
                            sx={{
                                backgroundColor: selectedLevel === livello.term_id ? '#2196f3' : '#ff9800',
                                color: 'white',
                            }}
                            onClick={() => fetchEmailTemplates(livello.term_id)}
                        >
                            {livello.name}
                        </Button>
                    ))}
                </Box>
                {selectedLevel ? (
                    loading ? (
                        <CircularProgress />
                    ) : (
                        <TemplateEmailTable
                            emailTemplates={emailTemplates}
                            fetchEmailTemplates={() => fetchEmailTemplates(selectedLevel)}
                            handleEdit={handleOpenEditor}
                        />
                    )
                ) : (
                    <Typography variant="h6" gutterBottom>
                        Selezionare un livello cliccando su uno dei pulsanti
                    </Typography>
                )}
            </Box>
            <TemplateEmailEditor
                open={editorOpen}
                handleClose={handleCloseEditor}
                handleSave={handleSaveTemplate}
                templateId={templateId}
                fetchEmailTemplates={() => fetchEmailTemplates(selectedLevel)}
                selectedLevel={selectedLevel} // Pass selectedLevel as a prop
            />
        </Layout>
    );
};

export default TemplateEmail;
