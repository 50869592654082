import React from 'react';
import { Box } from '@mui/material';
import Layout from './Layout';

const AreaDownload = () => {
    const token = localStorage.getItem('token'); // Recupera il token dal local storage
    const url = `https://cloud.hocus-lotus.edu/areapubblica/public/?token=${token}`; // Crea l'URL con il token

    return (
        <Layout>
            <Box sx={{ width: '100%', height: '100vh', mx: 'auto', px: 2, marginLeft: 0, overflow: 'hidden' }}>
                <iframe
                    src={url}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Area Download"
                    allowFullScreen
                />
            </Box>
        </Layout>
    );
};

export default AreaDownload;
